import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Input,
  Label,
  Form,
  Button,
  InputGroupText,
  InputGroup,
} from "reactstrap"

// Formik Validation

// action
import { apiError } from "../../store/actions"
import { post } from "../../helpers/api_helper"

//redux
import { useDispatch } from "react-redux"

import anim_logo from "../../assets/images/ainimationlogo.gif"
import { Toaster } from "react-hot-toast"
import { toast } from "react-hot-toast"
import Recaptcha from "components/Recaptcha"

import "../../assets/css/loginfooter.css"
import img11 from "assets/images/img11.png"
import img12 from "assets/images/img12.png"
import config from "../../config"
import { GoogleTagManager, initGA } from "../../assets/js/gtag"
import AuthHeader from "components/Common/AuthHeader"
import Account from "../../assets/images/NewAccount.svg"
import Apple from "../../assets/images/apple.png"
import Google from "../../assets/images/google.png"
import { RiUser3Line } from "react-icons/ri"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import "./stylesheet.css"
import AuthImg from "../../assets/images/AuthImg.svg"
import AuthFooter from "components/Common/AuthFooter"
import LockIcon from "../../assets/images/Lock.svg"
import EnvelopeIcon from "../../assets/images/Envelope.svg"
const Register = props => {
  //meta title
  document.title = `Register | ${config.APP_DETAILS.APP_NAME}`
  const [responseSuccess, setResponseSuccess] = useState(false)
  const [responseError, setResponseError] = useState(false)
  const [responseMessage, setResponseMessage] = useState("")
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const [PWFocus, setPWFocus] = useState(false)
  const [passValid, setPassValid] = useState(null)
  const [confirmValid, setConfirmValid] = useState(true)
  const [firstCondition, setFirstCondition] = useState(false)
  const [secondCondition, setSecondCondition] = useState(false)
  const [thirdCondition, setThirdCondition] = useState(false)
  const [forthCondition, setForthCondition] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const dispatch = useDispatch()

  let registering = false

  const handleSubmit = async () => {
    try {
      if (password == confirmPassword) {
        if (recaptchaToken) {
          if (!registering) {
            registering = true
            let body = {
              firstName: firstName,
              lastName: lastName,
              email: email,
              password: password,
            }

            const loading = toast.loading("Registering account")
            await post("/user/register", body).then(response => {
              if (response.status === 200) {
                toast.remove(loading)
                toast.success(response.message)
                setTimeout(() => {
                  props.history.push(response.url)
                }, 1000)
              } else {
                toast.remove(loading)
                toast.error(response.message)
              }
              registering = false
            })
          }
        } else {
          toast.error("Please verify that you are not a robot")
        }
      } else {
        toast.error("Password and confirm password do not match")
      }
    } catch (e) {
      // console.log(e);
    }
  }

  // // console.log("user", user);

  useEffect(() => {
    dispatch(apiError(""))

    if (config.site.MODE == "production") {
      // initGA();
      GoogleTagManager("UA-254226105-1")
      GoogleTagManager("G-YQ0XXFE0NR")
    }
  }, [])

  const handleUpdate = newValue => {
    setRecaptchaToken(newValue)
  }

  function hasLowerCase(str) {
    return /[a-z]/.test(str)
  }

  function hasUpperCase(str) {
    return /[A-Z]/.test(str)
  }

  function hasNumber(str) {
    return /[0-9]/.test(str)
  }

  function hasSpecial(str) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return format.test(str)
  }

  const handleFocus = () => {
    // console.log("setPWFocus true")
    setPWFocus(true)
  }

  const handleFocusOut = () => {
    // console.log("setPWFocus false")
    setPWFocus(false)
  }

  const handlePassword = e => {
    setPassword(e.target.value)
    //// console.log("e.target.value.length",e.target.value.length);
    if (e.target.value.length >= 8) {
      setFirstCondition(true)
      // //// console.log(firstCondition)
    } else {
      setFirstCondition(false)
    }

    if (hasUpperCase(e.target.value) && hasLowerCase(e.target.value)) {
      setSecondCondition(true)
    } else {
      setSecondCondition(false)
    }

    if (hasNumber(e.target.value)) {
      setThirdCondition(true)
    } else {
      setThirdCondition(false)
    }

    if (hasSpecial(e.target.value)) {
      setForthCondition(true)
    } else {
      setForthCondition(false)
    }

    if (
      e.target.value.length >= 7 &&
      hasUpperCase(e.target.value) &&
      hasLowerCase(e.target.value) &&
      hasNumber(e.target.value) &&
      hasSpecial(e.target.value)
    ) {
      setPassValid(true)
    } else {
      setPassValid(false)
    }

    if (confirmPassword === e.target.value) {
      setConfirmValid(true)
    } else {
      setConfirmValid(false)
    }
  }

  const handleConfirmPassword = e => {
    setConfirmPassword(e.target.value)
    if (password === e.target.value) {
      setConfirmValid(true)
    } else {
      setConfirmValid(false)
    }
  }
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <React.Fragment>
      {/* <div
        style={{
          backgroundImage: `url(${bgimg})`,
          width: "100%",
          marginBottom: "20px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "95px",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "999",
        }}
        className="navbar-header"
      >
        <a href={`${config.main_web.URL}`}>
          <img
            className="mx-5"
            // height={"120px"}
            width="230px"
            src={namelogo}
            alt=""
          />
        </a>
      </div> */}
      <Toaster position="top-center" reverseOrder={false} />
      {/* className={"col-md-12 p-2"} */}
      <div>
        {/* //loginpage below */}
        <div
          className="col-xl-11 "
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: "10px",
              margin: 0,
            }}
          >
            <CardBody
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Row>
                <Col xl={6} className="segap p-0 ps-3 pe-3 ps-md-5 pe-md-5">
                  <AuthHeader
                    label="Already have an account?"
                    link="/login"
                    btnLabel="Login"
                  />
                  <div className="d-flex flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3 width80">
                      <img src={Account} alt="Account" />
                      <h3 className="mt-2">Create a new account</h3>
                      <p className="mb-3">Enter your details to register</p>
                      <div className="d-flex w-100 justify-content-center gap-5">
                        <Button className="pt-1 pb-1 googleBtn">
                          <img src={Apple} alt="apple" />
                        </Button>
                        <Button className="pt-1 pb-1 googleBtn">
                          {" "}
                          <img src={Google} alt="google" />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center justify-content-center w-100 mt-4 mb-3 gap-2">
                        <div
                          style={{
                            border: "1px solid #e1e4ea",
                            width: "35%",
                            height: "1px",
                          }}
                        ></div>
                        <p className="mb-0 mx-1">OR</p>
                        <div
                          style={{
                            border: "1px solid #e1e4ea",
                            width: "35%",
                            height: "1px",
                          }}
                        ></div>
                      </div>
                    </div>
                    <CardBody className="pt-0 width80">
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={async e => {
                            e.preventDefault()
                            await handleSubmit()
                            return false
                          }}
                        >
                          {responseSuccess ? (
                            <Alert color="success">{responseMessage}</Alert>
                          ) : null}

                          {responseError ? (
                            <Alert color="danger">{responseMessage}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">
                              Full Name{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                *
                              </span>
                            </Label>
                            <InputGroup
                              style={{
                                border:
                                  // validation.touched.email &&
                                  // validation.errors.email
                                  // ? "1px solid red":
                                  "1px solid #ced4da",
                                borderRadius: "5px",
                              }}
                            >
                              <InputGroupText
                                style={{
                                  // border: "1px solid #ced4da",
                                  // borderTopLeftRadius: "10px",
                                  // borderBottomLeftRadius: "10px",
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <RiUser3Line />
                              </InputGroupText>
                              <Input
                                name="firstName"
                                type="text"
                                placeholder="Enter First Name"
                                onChange={e => {
                                  setFirstName(e.target.value)
                                }}
                                value={firstName}
                                required={true}
                                style={{
                                  // borderTopLeftRadius: "0",
                                  // borderBottomLeftRadius: "0",
                                  // borderTopRightRadius: "10px",
                                  // borderBottomRightRadius: "10px",
                                  border: "none",
                                }}
                              />
                            </InputGroup>
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              Last Name{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                *
                              </span>
                            </Label>
                            <InputGroup
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "5px",
                              }}
                            >
                              <InputGroupText
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <RiUser3Line />
                              </InputGroupText>
                              <Input
                                name="lastName"
                                type="text"
                                placeholder="Enter Last Name"
                                onChange={e => {
                                  setLastName(e.target.value)
                                }}
                                value={lastName}
                                required={true}
                                style={{
                                  border: "none",
                                }}
                              />
                            </InputGroup>
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              Email{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                *
                              </span>
                            </Label>
                            <InputGroup
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "5px",
                              }}
                            >
                              <InputGroupText
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <img src={EnvelopeIcon} alt="EnvelopeIcon" />
                              </InputGroupText>
                              <Input
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email"
                                type="email"
                                onChange={e => {
                                  setEmail(e.target.value)
                                }}
                                value={email}
                                required={true}
                                style={{
                                  border: "none",
                                }}
                              />
                            </InputGroup>
                          </div>
                          <div
                            className="mb-3"
                            style={{ position: "relative" }}
                          >
                            <Label className="form-label">
                              Password{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                *
                              </span>
                            </Label>
                            <InputGroup
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "5px",
                              }}
                            >
                              <InputGroupText
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <img src={LockIcon} alt="LockIcon" />
                              </InputGroupText>
                              <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                value={password}
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$"
                                onChange={handlePassword}
                                onFocus={handleFocus}
                                onBlur={handleFocusOut}
                                required={true}
                                style={{
                                  border: "none",
                                }}
                              />
                              <InputGroupText
                                onClick={toggleShowPassword}
                                style={{
                                  cursor: "pointer",
                                  border: "none",
                                  background: "white",
                                }}
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </InputGroupText>
                            </InputGroup>

                            <span style={{ color: "red", fontSize: "11px" }}>
                              {passValid == null || passValid == true
                                ? ""
                                : "Please fulfill the password requirements"}
                            </span>
                            <div
                              className={"bg-white"}
                              style={{
                                display: PWFocus ? "block" : "none",
                                position: "absolute",
                                bottom: "-130px",
                                backgroundColor: "white",
                                right: "0",
                                paddingRight: "30px",
                                paddingLeft: "30px",
                                borderRadius: "10px",
                                border: "1px solid #333",
                                zIndex: "99999",
                              }}
                            >
                              <p style={{ fontWeight: "600" }}>
                                Password Requirements
                              </p>
                              <ul
                                id="ul"
                                style={{
                                  fontSize: "12px",
                                  listStyleType: "none",
                                  marginLeft: "-50px",
                                }}
                              >
                                <li
                                  className={
                                    firstCondition
                                      ? "conditionPass"
                                      : "conditionFail"
                                  }
                                >
                                  At least 8 characters.
                                </li>
                                <li
                                  className={
                                    secondCondition
                                      ? "conditionPass"
                                      : "conditionFail"
                                  }
                                >
                                  Contains uppercase and lowercase letters.
                                </li>
                                <li
                                  className={
                                    thirdCondition
                                      ? "conditionPass"
                                      : "conditionFail"
                                  }
                                >
                                  Contains numbers.
                                </li>
                                <li
                                  className={
                                    forthCondition
                                      ? "conditionPass"
                                      : "conditionFail"
                                  }
                                >
                                  Contains at least one special character,{" "}
                                  <br />
                                  e.g. _ ! @ # ? ]
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className={"mb-3"}>
                            <Label className="form-label">
                              Confirm Password{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                *
                              </span>
                            </Label>

                            <InputGroup
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "5px",
                              }}
                            >
                              <InputGroupText
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <img src={LockIcon} alt="LockIcon" />
                              </InputGroupText>
                              <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Confirm password"
                                // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$"
                                onChange={handleConfirmPassword}
                                value={confirmPassword}
                                // onFocus={handleFocus}
                                // onBlur={handleFocusOut}
                                required={true}
                                disabled={password ? false : true}
                                style={{
                                  border: "none",
                                }}
                              />
                              <InputGroupText
                                onClick={toggleShowPassword}
                                style={{
                                  cursor: "pointer",
                                  border: "none",
                                  background: "white",
                                }}
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </InputGroupText>
                            </InputGroup>

                            <span style={{ color: "red", fontSize: "11px" }}>
                              {confirmValid ? "" : "Password do not match"}
                            </span>
                          </div>
                          <Row className="rec">
                            <Col>
                              <Recaptcha onUpdate={handleUpdate} />
                            </Col>
                          </Row>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn text-white m-auto btn-block ntb w-100"
                              type="submit"
                              style={{
                                backgroundColor: "#835af4",
                                borderRadius: "12px",
                              }}
                              onMouseEnter={e =>
                                (e.target.style.backgroundColor = "#6999e0")
                              }
                              onMouseLeave={e =>
                                (e.target.style.backgroundColor = "#835af4")
                              }
                              disabled={
                                confirmValid ? (passValid ? false : true) : true
                              }
                            >
                              Sign up
                            </button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </div>
                  {/* <hr />
                  <div className="mt-2 text-center">
                    <p>
                      Already have an account ?{" "}
                      <Link to="/login" className="fw-medium text-danger">
                        {" "}
                        Login
                      </Link>{" "}
                    </p>
                  </div> */}
                  <AuthFooter />
                </Col>
                <Col
                  xl={6}
                  style={{
                    background: "#fcf7fa",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={AuthImg}
                      alt="AuthImg"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* <LoginFooter/> */}
    </React.Fragment>
  )
}

export default Register
