import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { get, post } from "../../helpers/api_helper"
import { Line, Bar } from "react-chartjs-2"
import moment from "moment-timezone"
import fedex from "../../assets/images/fedex.png"
import ups from "../../assets/images/ups-logo.png"
import usps from "../../assets/images/usps-logo.png"
import dhl from "../../assets/images/dhl-logo.png"
import loader from "../../assets/images/loader.gif"
import { amountFormat } from "../../assets/js/numberFormatter"
import config from "../../config"
import { initGA } from "../../assets/js/gtag"

const Dashboard = props => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const [reports, setReport] = useState([])
  const [numberOfDays, setNumberOfDays] = useState(1)

  const [mainLoading, setMainLoading] = useState(true)

  const [selectedView, setSelectedView] = useState("day")
  const [selectedCategory, setSelectedCategory] = useState("label")
  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState("all")

  const [dataLoading, setDataLoading] = useState(true)
  // let reports = [];

  const [lastThirtyDays, setLastThirtyDays] = useState([])
  const [orders, setOrders] = useState(0)

  const [loadGraph, setLoadGraph] = useState(false);

  const handleChart = (numberOfDays, carrier) => {
    setDataLoading(true)
    setNumberOfDays(numberOfDays)
    // console.log("carrier",carrier);
    if (JSON.parse(localStorage.getItem("authUser")).role_id === 1) {
      // let arr = [];
      retrieveOrders()
        .then(apiResponse => {
          let arr = []
          if (apiResponse.length > 0) {
            let response = []
            if (carrier !== "all") {
              response = apiResponse.filter(
                el => el.carrier_name == carrier
              )
            } else {
              response = apiResponse
            }

            if (numberOfDays === "day") {
              let currentDate = new Date()
              // let startOfYear = new Date(currentDate.getFullYear(), 0, 1);
              let startOfYear = moment(currentDate).tz(moment.tz.guess())
                .subtract(1, "month")
                .toDate()
              let diff = currentDate - startOfYear
              let diffInDays = diff / (1000 * 60 * 60 * 24)

              for (let i = 0; i <= diffInDays; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "day").format("DD-MMM"),
                  value: 0,
                  price: 0,
                })
              }

              // console.log("diffInDays", Math.round(diffInDays))
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date === moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
                // else {
                //   arr.push({"date": moment(response[i].created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY"), "value": 1});
                // }
              }
            }
            else if (numberOfDays === "month") {
              let currentDate = new Date()
              let startOfYear = new Date(currentDate.getFullYear(), 0, 1)
              let currentMonth = parseInt(moment(new Date()).tz(moment.tz.guess()).format("MM"))
              for (let i = 0; i < currentMonth; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "month").format("MMM-YYYY"),
                  value: 0,
                  price: 0,
                })
              }
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date ===
                    moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
              }
            }

            // console.log("arr", arr)
            // console.log("response", response)
          }
          return arr
        })
        .then(arr => {
          // console.log("setLastThirtyDays", arr)
          setDataLoading(false)
          setLastThirtyDays(arr)
        })
    } else if (JSON.parse(localStorage.getItem("authUser")).role_id === 2) {
      setLoadGraph(true)
      retrieveOrdersCompleted(numberOfDays)
        .then(apiResponse => {
          let arr = []
          if (apiResponse.length > 0) {
            let response = []
            if (carrier !== "all") {
              response = apiResponse.filter(
                el => el.carrier_name == carrier
              )
            } else {
              response = apiResponse
            }

            response.sort(function (a, b) {
              return new Date(a.created_at) - new Date(b.created_at)
            })

            if (numberOfDays === "day") {
              let currentDate = new Date()
              // let startOfYear = new Date(currentDate.getFullYear(), 0, 1);
              let startOfYear = moment(currentDate).tz(moment.tz.guess())
                .subtract(1, "month")
                .toDate()
              let diff = currentDate - startOfYear
              let diffInDays = diff / (1000 * 60 * 60 * 24)

              for (let i = 0; i <= diffInDays; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "day").format("DD-MMM"),
                  value: 0,
                  price: 0,
                })
              }

              // console.log("diffInDays", Math.round(diffInDays))
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date === moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("DD-MMM")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
                // else {
                //   arr.push({"date": moment(response[i].created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY"), "value": 1});
                // }
              }
            } else if (numberOfDays === "month") {
              let currentDate = new Date()
              let startOfYear = new Date(currentDate.getFullYear(), 0, 1)
              let currentMonth = parseInt(moment(new Date()).tz(moment.tz.guess()).format("MM"))
              for (let i = 0; i < currentMonth; i++) {
                arr.push({
                  date: moment(startOfYear).tz(moment.tz.guess()).add(i, "month").format("MMM-YYYY"),
                  value: 0,
                  price: 0,
                })
              }
              for (let i = 0; i < response.length; i++) {
                let found = arr.some(
                  el =>
                    el.date ===
                    moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                )
                if (found) {
                  let index = arr.findIndex(
                    el =>
                      el.date ===
                      moment(response[i].created_at.toString()).tz(moment.tz.guess()).format("MMM-YYYY")
                  )
                  arr[index].value = parseInt(arr[index].value) + 1
                  arr[index].price =
                    parseInt(arr[index].price) +
                    parseFloat(
                      response[i].discounted_price
                        ? response[i].discounted_price
                        : 0
                    )
                }
              }
            }

            // console.log("arr", arr)
            // console.log("response", response)
          }
          return arr
        })
        .then(arr => {
          setDataLoading(false)
          setLastThirtyDays(arr)
        })

      // let arr = [];
      // retrieveOrdersCompleted().then((response) => {
      //   // console.log("responseresponse", response);
      //

      //   // console.log("sorted responseresponse", response);
      //   for (let i = 0; i <= numberOfDays; i++) {
      //     let date = moment(new Date()).tz(moment.tz.guess()).subtract(i, 'day').format("DD-MMM-YYYY");
      //     let filtered = response.filter(el => moment(el.created_at).tz(moment.tz.guess()).format("DD-MMM-YYYY") === date).length;
      //     arr.push({ "date": date, "value": filtered });
      //   }
      // }).then(() => {
      //   // console.log("setLastThirtyDays", arr);
      //   setLastThirtyDays(arr);
      // });
    }
  }


  useEffect(() => {
    setMainLoading(true)
    retrieveCarriers()
    if (JSON.parse(localStorage.getItem("authUser")).role_id === 1) {
      let arr = []
      get("/admin/get-dashboard-data", { headers }).then(response => {
        if (response.status === 200) {
          // setUsers(response.users);
          // setCarrierIds(response.carrierIds);
          // setTickets(response.tickets);
          // setServices(response.services);

          setReport([
            {
              title: "Total Users",
              iconClass: "bxs-group",
              description: response.users,
            },
            {
              title: "Total Services",
              iconClass: "bxs-envelope",
              description: response.services,
            },
            {
              title: "Active Carrier Ids",
              iconClass: "bxs-truck",
              description: response.carrierIds.length,
            },
            {
              title: "Open Tickets",
              iconClass: "bxs-bookmarks",
              description: response.tickets,
            },
            {
              title: "Today's Completed Orders",
              iconClass: "bx-check-circle",
              description: response.today_completed_order_count,
            },
            {
              title: "Pending Cancelled Orders",
              iconClass: "bx-x",
              description: response.pending_cancelled_order_count,
            },
            {
              title: "Connected Stores",
              iconClass: "bx-store",
              description: response.connected_stores_count,
            },
            {
              title: "Running Scheduler",
              iconClass: "bx-stopwatch",
              description: response.active_cron_count,
            },
            // {
            //   title: "Waiting Tickets",
            //   iconClass: "bxs-bookmarks",
            //   description: response.tickets.filter(el => el.status === "Waiting For Response").length,
            // }
          ])
        }
      })
    }
    else if (JSON.parse(localStorage.getItem("authUser")).role_id === 2) {
      // if(config.site.MODE == "production") {
      //   initGA();
      // }
      // console.log(
      //   "'/user/get-dashboard-data",
      //   "/user/get-dashboard-data/" +
      //     JSON.parse(localStorage.getItem("authUser")).id
      // )
      get(
        "/user/get-dashboard-data/" +
          JSON.parse(localStorage.getItem("authUser")).id,
        { headers }
      ).then(response => {
        // console.log("user/get-dashboard-data", response)
        if (response.status === 200) {
          // setUser(response.user);
          // setOrders(response.orders);
          // setUserTickets(response.tickets);
          setReport([
            {
              title: "Total Balance",
              iconClass: "bx-dollar",
              description: "$" + amountFormat(response.user.balance),
            },
            {
              title: "Total Awaiting Shipment",
              iconClass: "bxs-box",
              description: response.orders.orders_count,
            },
            {
              title: "Connected Stores",
              iconClass: "bxs-store",
              description: response.stores.store_count,
            },
            {
              title: "Open Tickets",
              iconClass: "bxs-bookmarks",
              description: response.tickets.ticket_count,
            },
            // {
            //   title: "Completed Orders",
            //   iconClass: "bxs-store",
            //   description: response.completed.completed_count,
            // },
          ])
        }
      })
      handleChart("day", selectedCarrier)
    }


    setTimeout(() => {
      // setSubscribemodal(true);
    }, 2000)
  }, [])

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carrier-names", { headers }).then(response => {
        setCarriers(response.data)
        setTimeout(() => {
          setMainLoading(false)
        }, 1500)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrders = async () => {
    try {
      let res = []
      await get("/admin/get-user-completed-orders", { headers }).then(
        response => {
          // // console.log("response", response);
          // setOrders(response.data);
          res = response.data
        }
      )
      return res
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrdersCompleted = async (numberOfDays) => {
    try {
      let res = []
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        type: numberOfDays
      }
      await post("/user/get-orders-completed-for-dashboard", body, { headers }).then(
        response => {
          // console.log("response", response)
          res = response.data
        }
      )
      return res
    } catch (e) {
      // console.log(e)
    }
  }

  // const data = {
  //   labels: lastThirtyDays.map(el => el.date),
  //   datasets: [
  //     {
  //       label: "Label Generated",
  //       animated:true,
  //       fill: false,
  //       lineTension: 0,
  //       borderColor:"#ffbe00",
  //       pointBackgroundColor: "#330000",
  //       pointBorderColor: "#330000",
  //       pointBorderWidth: 6,
  //       pointHoverRadius: 5,
  //       pointHoverBorderColor: "#330000",
  //       pointHoverBorderWidth: 2,
  //       pointRadius: 1,
  //       pointHitRadius: 10,
  //       data: lastThirtyDays.map(el => el.value),
  //     }
  //   ]
  // }

  const data = {
    labels: lastThirtyDays.map(el => el.date),
    datasets: [
      {
        label: selectedCategory == "label" ? "Label Generated" : "Cost",
        animated: true,
        fill: true,
        backgroundColor: "#fc8d42",
        barThickness: 20,
        data: lastThirtyDays.map(el =>
          selectedCategory == "label" ? el.value : el.price
        ),
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false, //This will do the task
    },
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            zeroLineColor: localStorage.getItem("theme")=="dark" ? "#a6b0c4":"black"
          },
          ticks: {
            fontColor: localStorage.getItem("theme")=="dark" ? "white":"black",
            suggestedMin: 0, // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true, // minimum value will be 0.
            userCallback: function (label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label
              }
            },
          },
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: {
            zeroLineColor: localStorage.getItem("theme")=="dark" ? "#a6b0c4":"black"
          },
          ticks: {
            fontColor: localStorage.getItem("theme")=="dark" ? "white":"black",
          },
        },
      ],
    },
  }

  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
  }

  const handleLoadGraph = () => {
    setLoadGraph(true)
    handleChart("day", selectedCarrier)
  }

  //meta title
  document.title = `Dashboard | ${config.APP_DETAILS.APP_NAME}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("NullShip")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row style={{ position: "relative" }}>
            {JSON.parse(localStorage.getItem("authUser")).role_id !== 3 ? (
              <div
                id="loader"
                className={"align-items-center justify-content-center"}
                style={{
                  display: mainLoading ? "flex" : "none",
                  alignItems: "center",
                  margin: "auto",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: (localStorage.getItem("theme")?(localStorage.getItem("theme")=="dark"?"#0f0f0f":"white"):"white"),
                  zIndex: "99999",
                  top: "0",
                  left: "0",
                  borderRadius:"5px"
                }}
              >
                <img style={{ width: "10%" }} src={loader} />
              </div>
            ) : (
              ""
            )}
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col
                    md={
                      JSON.parse(localStorage.getItem("authUser")).role_id === 1
                        ? "3"
                        : "6"
                    }
                    key={"_col_" + key}
                  >
                    <Card className="mini-stats-wid slide">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-secondary fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description} {report.title == "Total Awaiting Shipments"?<span className={"text-secondary"} style={{fontSize:"10px"}}>(All Stores)</span>:null}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-custom align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-custom">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            {JSON.parse(localStorage.getItem("authUser")).role_id !== 3 ? (
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div
                      className={"row"}
                      style={{
                        justifyContent: "center",
                        marginBottom: "-20px",
                      }}
                    >


                      <Col
                        md={2}
                        onClick={() => {
                          setSelectedCarrier("all")
                          handleChart(selectedView, "all")
                        }}  style={{display:"none"}}
                      >
                        <Card
                          className={"mini-stats-wid"}
                          style={{ cursor: "pointer" }}
                        >
                          <CardBody
                            className={
                              "border p-3 rounded " +
                              (selectedCarrier == "all"
                                ? "carrier-selected"
                                : "")
                            }
                          >
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <h5 className="mb-0">All</h5>
                                {selectedCarrier == "all" ? (
                                  <p className="text-muted fw-medium text-md-start">
                                    {dataLoading ? (
                                      "Loading..."
                                    ) : (
                                      <>
                                        {selectedCategory == "label" ? (
                                          <i className={"bx bx-bookmark"}></i>
                                        ) : (
                                          "$"
                                        )}
                                        {lastThirtyDays.length > 0
                                          ? amountFormat(
                                              lastThirtyDays
                                                .map(item =>
                                                  selectedCategory == "label"
                                                    ? item.value
                                                    : item.price
                                                )
                                                .reduce(
                                                  (prev, next) => prev + next
                                                )
                                            )
                                          : ""}
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p className="text-muted fw-medium"></p>
                                )}
                              </div>
                              <div
                                style={{ width: "40px", height: "40px" }}
                                className={
                                  "avatar-xm p-2 rounded-circle bg-white align-self-center mini-stat-icon " +
                                  (selectedCarrier == "all"
                                    ? "border-selected"
                                    : "border-default")
                                }
                              >
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-white"
                                  }
                                >
                                  <i
                                    style={{ color: "#333" }}
                                    className={"bx bxs-box font-size-20"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      {carriers.map((part, id) => {
                        return (
                          <Col
                            md={2}
                            key={id}
                            onClick={() => {
                              setSelectedCarrier(part.name)
                              handleChart(selectedView, part.name)
                            }}
                            style={{ cursor: "pointer",display:"none" }}
                          >
                            <Card className={"mini-stats-wid "}>
                              <CardBody
                                className={
                                  "border p-3 rounded " +
                                  (selectedCarrier == part.name
                                    ? "carrier-selected"
                                    : "")
                                }
                              >
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <h5 className="mb-0">{part.name}</h5>
                                    {selectedCarrier == part.name ? (
                                      <p className="text-muted fw-medium text-md-start">
                                        {dataLoading ? (
                                          "Loading..."
                                        ) : (
                                          <>
                                            {selectedCategory == "label" ? (
                                              <i
                                                className={"bx bx-bookmark"}
                                              ></i>
                                            ) : (
                                              "$"
                                            )}
                                            {lastThirtyDays.length > 0
                                              ? amountFormat(
                                                  lastThirtyDays
                                                    .map(item =>
                                                      selectedCategory ==
                                                      "label"
                                                        ? item.value
                                                        : item.price
                                                    )
                                                    .reduce(
                                                      (prev, next) =>
                                                        prev + next
                                                    )
                                                )
                                              : ""}
                                          </>
                                        )}
                                      </p>
                                    ) : (
                                      <p className="text-muted fw-medium"></p>
                                    )}
                                  </div>
                                  <div
                                    style={{ width: "40px", height: "40px" }}
                                    className={
                                      "avatar-xm p-2 rounded-circle bg-white align-self-center mini-stat-icon " +
                                      (selectedCarrier == part.name
                                        ? "border-selected"
                                        : "border-default")
                                    }
                                  >
                                    <span
                                      className={
                                        "avatar-title rounded-circle bg-white"
                                      }
                                    >
                                      <img
                                        src={
                                          part.name == "FedEx"
                                            ? fedex
                                            : part.name == "UPS" || part.name == "UPSv2"
                                            ? ups
                                            : part.name == "USPS"
                                            ? usps
                                            : dhl
                                        }
                                        height={"15px"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </div>
                    <br />
                    {/*<h4 className="card-title mb-4">Last {numberOfDays} Days Label Generation Report</h4>*/}
                    {loadGraph?
                      <>
                        <div
                          className={"col-md-12 mb-3"}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ width: "fit-content" }}>
                            <div className="btn-group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="cat-radio"
                                id="cat-radio1"
                                autoComplete="off"
                                defaultChecked
                              />
                              <label
                                className="btn btn-outline-dark"
                                htmlFor="cat-radio1"
                                onClick={() => {
                                  setSelectedCategory("label")
                                  handleChart(selectedView, selectedCarrier)
                                }}
                              >
                                Label Generated
                              </label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="cat-radio"
                                id="cat-radio3"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-dark"
                                htmlFor="cat-radio3"
                                onClick={() => {
                                  setSelectedCategory("price")
                                  handleChart(selectedView, selectedCarrier)
                                }}
                              >
                                Cost
                              </label>
                            </div>
                            <div
                              className={"vr"}
                              style={{ marginLeft: "10px", marginRight: "10px" }}
                            ></div>
                            <div className="btn-group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="vbtn-radio"
                                id="vbtn-radio1"
                                autoComplete="off"
                                defaultChecked
                              />
                              <label
                                className="btn btn-outline-dark"
                                htmlFor="vbtn-radio1"
                                onClick={() => {
                                  // setNumberOfDays(1)
                                  setSelectedView("day")
                                  handleChart("day", selectedCarrier)
                                }}
                              >
                                Day
                              </label>
                              {/*<input type="radio" className="btn-check" name="vbtn-radio" id="vbtn-radio2" autoComplete="off" />*/}
                              {/*<label className="btn btn-outline-warning" htmlFor="vbtn-radio2" onClick={() => {*/}
                              {/*  // setNumberOfDays(7)*/}
                              {/*  handleChart("week")*/}
                              {/*}}>Week</label>*/}
                              <input
                                type="radio"
                                className="btn-check"
                                name="vbtn-radio"
                                id="vbtn-radio3"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-dark"
                                htmlFor="vbtn-radio3"
                                onClick={() => {
                                  // setNumberOfDays(30)
                                  setSelectedView("month")
                                  handleChart("month", selectedCarrier)
                                }}
                              >
                                Month
                              </label>
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "300px" }}>
                      {dataLoading ? (
                        <div
                          id="loader"
                          className={
                            "align-items-center justify-content-center"
                          }
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            backgroundColor: (localStorage.getItem("theme")?(localStorage.getItem("theme")=="dark"?"#0f0f0f":"white"):"white"),
                          }}
                        >
                          <img style={{ width: "5%" }} src={loader} />
                        </div>
                      ) : (
                        <Bar data={data} options={options} />
                      )}
                    </div>
                    </>:""}
                    {!loadGraph && JSON.parse(localStorage.getItem("authUser")).role_id == 1 ? <button className={"btn btn-dark"} onClick={handleLoadGraph}>Load Graph</button> : ""}
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
